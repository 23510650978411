enum RoutePath {
  Language = '/language',
  ManageMobi = '/managemobi',
  OpenApp = '/open-app/:platform',
  UpdateRequired = '/update-required',

  Login = '/login',
  LoginWithPassword = '/login/with-password',
  LoginWithCode = '/login/with-code',
  ForgotPassword = '/login/forgot-password',
  ForgotAccessCode = '/login/forgot-access-code',
  ForgotPasswordSuccess = '/login/forgot-password-success',
  LoginWithEmailLink = '/login/with-email-link',

  Event = '/event',
  EventList = '/event/list',
  EventDetail = '/event/:eventId',
  EventPeopleDetailOld = '/event/:eventId/people-old/:idOrEmail',
  EventCheck = '/event/:eventId/check/:action',
  EventPeopleSearch = '/event/:eventId/people',
  EventKiosk = '/event/:eventId/kiosk',

  Session = '/session',
  SessionList = '/session/list/events/:eventId/:goBack?',
  SessionPeopleDetail = '/session/sessions/:sessionId/events/:eventId/people/:idOrEmail/:mode?',
  SessionCheck = '/session/sessions/:sessionId/events/:eventId/check/:action',
  SessionPeopleSearch = '/session/sessions/:sessionId/events/:eventId/people',
  SessionKiosk = '/session/sessions/:sessionId/events/:eventId/kiosk',

  People = '/people',
  PersonDetails = '/people/:personIdOrEmail/:mode?',

  Kiosk = '/kiosk',
  KioskSetup = '/kiosk/setup/:eventId',
  KioskCheck = '/kiosk/check/:eventId',
  KioskCheckBySearch = '/kiosk/check/:eventId/search',

  KioskOld = '/kiosk-old',
  KioskEventOld = '/kiosk-old/events/:eventId',
  KioskUnlockWithPasswordOld = '/kiosk-old/unlock-with-password',
  KioskUnlockWithCodeOld = '/kiosk-old/unlock-with-code',
  KioskEventCheckOld = '/kiosk-old/events/:eventId/check',
  KioskEventPeopleSearchOld = '/kiosk-old/events/:eventId/people',
  KioskSessionCheckOld = '/kiosk-old/events/:eventId/sessions/:sessionId/check',
  KioskSessionPeopleSearchOld = '/kiosk-old/events/:eventId/sessions/:sessionId/people',

  PrintBadge = '/print-badge/:eventId',
  PrintBadgeScanner = '/print-badge/:eventId/scanner',
  PrintBadgeSearch = '/print-badge/:eventId/search',
  PrintBadgeFlow = '/print-badge/:eventId/flow/:checkInCode/:shouldCheckIn?',
  PrintBadgeTest = '/print-badge/:eventId/test',

  Printer = '/printer',
  PrinterSettings = '/printer/:eventId/settings',
  PrinterSelection = '/printer/:eventId/selection',
  PrinterError = '/printer/:eventId/error/:errorCode/:tries?',

  Lead = '/lead',
  LeadHome = '/lead/home',
  LeadList = '/lead/list',
  LeadAdd = '/lead/add',
  LeadEdit = '/lead/leads/edit',
  CompanyList = '/lead/company/list',
}

export default RoutePath;
